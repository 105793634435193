<!-- 二级页面顶部图片 -->
<template>
	<div class="tc">
		<img v-lazy="web" :key="web" class="xs-hide lazyPic" lazy="loading" />
		<img v-lazy="wap" :key="wap" class="sm-hide lazyPic" lazy="loading" />
	</div>
</template>
<script>
	export default {
		name: 'topImg',
		props: {
			web: {
				type: String,
			},
			wap: {
				type: String,
			}
		}
	}
</script>
<style scoped="scoped">
	.tc {
		text-align: center;
	}
</style>

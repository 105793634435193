<!-- 全球网络 -->
<template>
<div class="wordMap">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <div class="wCenter">
        <div class="content">
            <p class="title" v-if="config.c1&&config.c1.isShow">{{config.c1.title}}</p>
            <p class="content" v-if="config.c2&&config.c2.isShow">
                <img v-lazy="config.c2.img" :key="config.c2.img" class="lazyPic" lazy="loading" width="100%"/>
            </p>
        </div>
    </div>

    <div class="wCenter context" v-if="config.c3&&config.c3.isShow&&list&&list.length>0">
        <swiper :options="swiperOptionMobile" ref="mySwiper" v-if="isMobile">
            <swiper-slide v-for="(item,index) in list" :key="index">
                <div class="swiperContent">
                    <div class="item">
                        <p class="title">{{item.filialeName}}</p>
                        <p class="text">
                            <img src="../../assets/contentUs2.png" /><a :href="'tel:'+item.tel" target="_blank">{{item.tel}}</a>
                        </p>
                        <p class="text" v-if="item.fax">
                            <img src="../../assets/contentUs3.png" />{{item.fax}}
                        </p>

                        <p class="text">
                            <img src="../../assets/contentUs1.png" />{{item.filialeAddress?item.filialeAddress:'暂无'}}
                        </p>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
        <swiper :options="swiperOptionPc" ref="mySwiper" v-else>
            <swiper-slide v-for="(item,index) in list" :key="index">
                <div class="swiperContent">
                    <div class="item">
                        <p class="title">{{item.filialeName}}</p>
                        <p class="text">
                            <img src="../../assets/contentUs2.png" /><a :href="'tel:'+item.tel" target="_blank">{{item.tel}}</a>
                        </p>
                        <p class="text" v-if="item.email">
                            <img src="../../assets/contentUs3.png" />{{item.email}}
                        </p>

                        <p class="text" v-if="item.filialeAddress">
                            <img src="../../assets/contentUs1.png" />{{item.filialeAddress}}
                        </p>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
    </div>
</div>
</template>

<script>
import {
    getPageConfigByCode,
    getListWorldNetwork
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    name: '',
    components: {
        TopImg,
    },
    data() {
        return {
            list: [],
            config: {},
            swiperOptionMobile: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: false,
                autoplay: {
                    delay: 2500,
                },
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
            swiperOptionPc: {
                slidesPerView: 3,
                spaceBetween: 20,
                centeredSlides: true,
                autoplay: {
                    delay: 2500,
                },
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.app.isMobile;
        },
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'wordMap/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                    let c3 = this.config.c3
                    if (c3 && c3.isShow) {
                        this.getList()
                    }
                }
            })
        },
        getList() {
            getListWorldNetwork().then(res => {
                if (res.success) {
                    this.list = res.result
                    if (this.list.length > 0) {

                    }
                }
            })
        },

    },
}
</script>

<style lang="scss" scoped>
.wordMap {
    .content {
        .title {
            color: #333333;
            font-size: 0.34rem;
            margin-top: 0.18rem;
            margin-bottom: 0.6rem;
            font-weight: bold;
            text-align: center;
        }

    }

    .swiperContent {
        display: flex;
        flex-wrap: wrap;
        margin: 0.5rem 0;

        @media (max-width:767px) {
            margin: 0;
        }

        .item {
            width: 4rem;
            padding: 0.15rem 0.25rem;
            // margin: 0 0.4rem 0 0;
            background: url('../../assets/contextBg.png');
            border-radius: 0.1rem;
            background-size: 100% 100%;

            &:nth-child(3n) {
                margin-right: 0;
            }

            @media (max-width: 767px) {
                width: 100%;

                &,
                &:nth-child(3n) {
                    margin: 0;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            .name {
                color: #333333;
                font-size: 0.18rem;
                margin: 0.1rem 0;
                font-weight: bold;
            }

            .title {
                color: #333333;
                font-size: 0.18rem;
                margin-bottom: 0.1rem;
                font-weight: bold;
				@media (max-width:767px) {
				    font-size: 0.4rem;
					margin-bottom: 0.23rem;
				}
            }

            .line {
                background-color: #333333;
                width: 0.5rem;
                height: 0.05rem;
                margin: 0.15rem 0;
            }

            .text {
                color: #333333;
                font-size: 0.18rem;
                margin-bottom: 0.1rem;
				@media (max-width:767px) {
				    font-size: 0.38rem;
				}

                img {
                    width: 0.22rem;
                    position: relative;
                    top: 0.03rem;
                    margin-right: 0.1rem;
					@media (max-width:767px) {
					    width: 0.4rem;
					}
                }
            }
        }

    }

    .wCenter .swiper-container {
        margin: 0 1rem;
        position: static;
    }

    .wCenter {
        position: relative;
    }

    .swiper-wrapper {
        display: flex;
        align-items: center;
    }

}
</style>
